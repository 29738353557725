import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import Layout from "../components/layout"
import SEO from "../components/seo"

const wrapperStyle = {
  width: "100%",
  height: "100%",
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    // console.log(post)
    // console.log(this.props.data)
    // console.log(this.props.path)

    const getImages = () => {
      return this.props.data.allFile.edges.filter(el => {
        if (!el.node.childImageSharp) {
          return false
        }
        return el.node.relativeDirectory.includes(this.props.path.split("/")[1])
      })
    }

    console.log(getImages())

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <article
          className={`post-content ${post.frontmatter.thumbnail || `no-image`}`}
        >
          <header className="post-content-header">
            <h1 className="post-content-title">{post.frontmatter.title}</h1>
          </header>

          {/* {post.frontmatter.description && (
            <p className="post-content-excerpt">
              {post.frontmatter.description}
            </p>
          )} */}

          {post.frontmatter.thumbnail &&
            post.frontmatter.thumbnail.childImageSharp && (
              <div className="post-content-image">
                <Img
                  className="kg-image"
                  fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                  alt={post.frontmatter.title}
                />
              </div>
            )}

          <div
            className="post-content-body"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <div className="post-content-body">
            <div className="container-fluid">
              <div
                className="row zoomImages"
                style={{ border: "6px solid white" }}
              >
                {getImages().map((el, i) => {
                  return (
                    <Zoom>
                      <Img
                        key={i}
                        style={{ border: "6px solid white" }}
                        fluid={el.node.childImageSharp.fluid}
                        style={wrapperStyle}
                      />
                    </Zoom>
                  )
                })}
              </div>
            </div>
          </div>

          <footer className="post-content-footer">
            {/* There are two options for how we display the byline/author-info.
        If the post has more than one author, we load a specific template
        from includes/byline-multiple.hbs, otherwise, we just use the
        default byline. */}
          </footer>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allFile {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
          relativeDirectory
        }
      }
    }
  }
`
